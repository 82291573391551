import React from "react"

const IconBookmark = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      className={props.className}
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
    >
      <path
        d="M6.2,3.1H5.5C4.7,3.1,4,3.8,4,4.6v0.7c0,0.8,0.7,1.5,1.5,1.5h0.7c0.8,0,1.5-0.7,1.5-1.5V4.6C7.7,3.8,7,3.1,6.2,3.1z
	 M6.7,5.4c0,0.3-0.2,0.5-0.5,0.5H5.5C5.2,5.9,5,5.6,5,5.4V4.6c0-0.3,0.2-0.5,0.5-0.5h0.7c0.3,0,0.5,0.2,0.5,0.5V5.4z"
      />
      <path d="M9.5,5.5h6C15.8,5.5,16,5.3,16,5s-0.2-0.5-0.5-0.5h-6C9.2,4.5,9,4.7,9,5S9.2,5.5,9.5,5.5z" />
      <path
        d="M6.2,8.1H5.5C4.7,8.1,4,8.8,4,9.6v0.7c0,0.8,0.7,1.5,1.5,1.5h0.7c0.8,0,1.5-0.7,1.5-1.5V9.6C7.7,8.8,7,8.1,6.2,8.1z
	 M6.7,10.4c0,0.3-0.2,0.5-0.5,0.5H5.5c-0.3,0-0.5-0.2-0.5-0.5V9.6c0-0.3,0.2-0.5,0.5-0.5h0.7c0.3,0,0.5,0.2,0.5,0.5V10.4z"
      />
      <path d="M15.5,9.5h-6C9.2,9.5,9,9.7,9,10s0.2,0.5,0.5,0.5h6c0.3,0,0.5-0.2,0.5-0.5S15.8,9.5,15.5,9.5z" />
      <path
        d="M6.2,13.1H5.5c-0.8,0-1.5,0.7-1.5,1.5v0.7c0,0.8,0.7,1.5,1.5,1.5h0.7c0.8,0,1.5-0.7,1.5-1.5v-0.7C7.7,13.8,7,13.1,6.2,13.1z
	 M6.7,15.4c0,0.3-0.2,0.5-0.5,0.5H5.5c-0.3,0-0.5-0.2-0.5-0.5v-0.7c0-0.3,0.2-0.5,0.5-0.5h0.7c0.3,0,0.5,0.2,0.5,0.5V15.4z"
      />
      <path d="M15.5,14.5h-6C9.2,14.5,9,14.7,9,15s0.2,0.5,0.5,0.5h6c0.3,0,0.5-0.2,0.5-0.5S15.8,14.5,15.5,14.5z" />
    </svg>
  )
}

export default IconBookmark
