import React from "react"
import { isMobile } from "../../tools/isMobile"

const ProjectScene = props => {
  if (props.bottomScene && isMobile()) {
    return <div>{props.children}</div>
  }
  return (
    <div
      id={props.id}
      style={props.intro ? { minHeight: "60vh" } : { minHeight: "75vh" }}
      className="w-full p-6 projectScene"
    >
      {props.children}
    </div>
  )
}

export default ProjectScene
