import PropTypes from "prop-types"
import React from "react"
import { Box, Flex } from "@chakra-ui/react"
const ProjectPageCard = props => {
  return (
    <Flex
      p={["24px", "32px", "64px", "64px", "84px"]}
      pt={["40px", "48px", "64px", "64px", "84px"]}
      width="100%"
      position="relative"
      background="white"
      borderLeftRadius="30px"
      align="center"
      justify="center"
      maxWidth="1920px"
      // shadow="subtle"
      className="elevation_sm"
    >
      <Box maxWidth="1300px" width="100%">
        {props.children}
      </Box>
    </Flex>
  )
}

ProjectPageCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProjectPageCard
