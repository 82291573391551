import PropTypes from "prop-types"
import React from "react"
import { Box, Flex } from "@chakra-ui/react"

const ProjectPageWrapper = props => {
  return (
    <Flex
      position="relative"
      // pt={{ base: "80px", md: "120px" }}
      height="100%"
      minHeight="100vh"
      direction={["column-reverse", "column-reverse", "row"]}
      justify="flex-end"
      overflowX="hidden"
    >
      {/* ... Pagination Spacing */}
      <Box w="60px"></Box>
      {/* ContentArea */}
      <Flex
        w={["100%", "100%", "15%"]}
        justify="center"
        align={props.isSlider ? "flex-start" : "center"}
        p={["8px", "16px"]}
      >
        {/* Left Side */}
        {props.leftContent}
      </Flex>
      <Flex
        w={["100%", "100%", "85%"]}
        align="flex-end"
        justify="flex-end"
        padding="16px"
        pr="0px"
        direction="column"
      >
        {props.children}
      </Flex>
    </Flex>
  )
}

ProjectPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProjectPageWrapper
